body {
  .container-sm {
    max-width: 825px;
  }

  .breadcrumb {
    margin-bottom: 0;
  }

  .dropdown-item.active {
    color: black;
    background-color: #fbec5d;
  }

  .dropdown-item:hover {
    color: black;
    background-color: #fff6cc;
  }
}

.responsive-offcanvas {
  width: 100%;
  max-width: 100%;
  left: 0;
  transform: none;
  height: auto !important;
  padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .responsive-offcanvas {
    width: 75%;
    max-width: 600px !important;
    left: 50%;
    margin: 0 auto;
    height: auto !important;
    padding-bottom: 1.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

.messages {
  display: flex;
  flex-direction: column;
  font-family: "SanFrancisco";
  font-size: 1rem;
  /* background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 0.25rem;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.5rem 1.5rem; */
}

.messages div {
  border-radius: 1.15rem;
  line-height: 1.2;
  max-width: 80%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.messages div::before,
.messages div::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

div.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

div.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

/* collapse subsequent margin-top when messages are related */
.messages div.message-chain {
  margin-bottom: -0.5rem !important;
}

div[class^="from-"] {
  margin: 0.85rem 0 0.15rem;
  width: fit-content;
}

div.from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

div.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

div[class^="from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

div[class^="from-"].emoji::before {
  content: none;
}

.no-tail::before {
  display: none;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 1rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}

/* general styling */
@font-face {
  font-family: "SanFrancisco";
  src: url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff2")
      format("woff2"),
    url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff")
      format("woff");
}

.messages div .like-container {
  position: absolute;
  top: -22px;
  z-index: 100;
  max-width: none;
}

.like-container-right {
  right: -20px;
}

.like-container-left {
  left: -20px;
}

.like-container .like {
  display: flex;
  align-items: center;
  padding: 0;
  border: 1px solid #fff;
  border-radius: 12px;
  height: 24px;
  font-size: 1rem;
  font-weight: 600;
}

.messages small {
  max-width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
}

.system-message-container {
  position: relative;
  background: transparent;
  width: 84%;
  max-width: 100% !important;
  line-height: 2.2;
}

.system-message {
  position: relative;
  background: transparent;
  font-size: 0.85rem;
  color: black;
}

.system-message-title,
.system-message-description {
  position: relative;
  z-index: 2; /* Ensure the text is above the line */
  padding: 0 10px;
  background: white;
}

.system-message-title {
  text-align: left;
  padding: 0 10px 0 0 !important;
}

.system-message-description {
  text-align: right;
}

.system-message-line {
  position: absolute !important;
  padding: 0 !important;
  max-width: 100% !important;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #444444;
  z-index: 0;
  margin: 0 auto;
}

/* styles for links within .from-me */
div.from-me a,
div.from-me a:link,
div.from-me a:visited,
div.from-me a:hover,
div.from-me a:active {
  color: #fff;
}

/* styles for links within .from-them */
div.from-them a,
div.from-them a:link,
div.from-them a:visited,
div.from-them a:hover,
div.from-them a:active {
  color: #000;
}

table {
  font-size: 0.7rem;
  table-layout: fixed;
}

.animation-beat {
  animation: animation-beat 5s ease infinite;
}

@keyframes animation-beat {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1);
  }
}

.animation-flip {
  animation-name: animation-flip;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.animation-flip.flip-slow {
  animation-duration: 3s; /* Override the duration */
}

@keyframes animation-flip {
  50% {
    transform: rotate3d(0, 1, 0, -180deg);
  }
}

.animation-shake {
  animation-name: animation-shake;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: normal;
}

@keyframes animation-shake {
  0% {
    transform: rotate(-10deg);
  }
  2% {
    transform: rotate(10deg);
  }
  4%,
  12% {
    transform: rotate(-12deg);
  }
  6%,
  14% {
    transform: rotate(12deg);
  }
  8% {
    transform: rotate(-14.67deg);
  }
  10% {
    transform: rotate(14.67deg);
  }
  16% {
    transform: rotate(-8deg);
  }
  18% {
    transform: rotate(8deg);
  }
  20%,
  to {
    transform: rotate(0deg);
  }
}
